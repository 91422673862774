import React from 'react';

import { HeroBanner, CaseHeader, Testimonial, TextContent, Gallery, GalleryLayout, Team } from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';
import socialPreview from '../../../static/social-previews/co2in.png';

const CASE_ID = 'co2in';
const MODULE_NAME = 'co2in';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    bgLightGreen: '#ECFFE3',
    bgFadedGreen: '#FEFEF9',
    bgGreen: '#2FA875',
    bgDarkGreen: '#054938',
    black: '#000',
    white: '#fff',
};

const team = [
    { department: 'references.case.team.management', names: ['Marek Elznic'] },
    { department: 'references.case.team.design', names: ['Martin Jílek', 'Roman Gordienko', 'Markéta Hejná'] },
    { department: 'references.case.team.web', names: ['Jakub Baierl', 'Martin Foldyna', 'Ivan Harašta'] },
    {
        department: 'references.case.team.ios',
        names: ['Alexandr Grigoryev', 'Lukáš Hromadník', 'Karel Leinhäupl'],
    },
    {
        department: 'references.case.team.android',
        names: ['Petr Šíma'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Mariya Vorona'],
    },
];

interface Props {}
const Co2in = (props: Props) => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero.png`,
                    imgStyle: { objectFit: 'contain', objectPosition: 'bottom center' },
                    style: {
                        width: '100%',
                    },
                    alt: `case.${CASE_ID}.hero.project`,
                },
            }}
            extendMainContainer={{
                ...noPadding,
                maxWidth: 'unset',

                tablet: {
                    ...noPadding,
                },

                tabletLarge: {
                    ...noPadding,
                },
            }}
            headerTheme="dark"
            fullHeight={false}
            background={`linear-gradient(180deg, ${colors.bgLightGreen} 0%, ${colors.bgFadedGreen} 50%)`}
            textColor={colors.black}
        />
        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.FINANCE,
                outputs: [
                    'references.case.output.uxReview',
                    'references.case.output.design',
                    'references.case.output.ios',
                    'references.case.output.android',
                    'references.case.output.web',
                    'references.case.output.testing',
                ],
            }}
            background={`linear-gradient(180deg, ${colors.bgLightGreen} 0%, ${colors.bgFadedGreen} 100%)`}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            fullHeight={false}
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/3.png`,
                        imgStyle: {
                            objectFit: 'contain',
                        },
                        style: {
                            width: '70%',
                            maxWidth: '60rem',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/3_bg.png`,
                imgStyle: {
                    objectFit: 'contain',
                    objectPosition: '50% 25%',
                    width: '90%',
                    marginLeft: '5%',
                    marginRight: '5%',
                },
            }}
            background={colors.bgFadedGreen}
        />
        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
            background={`linear-gradient(180deg, ${colors.bgFadedGreen} 0%, ${colors.bgLightGreen} 100%)`}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            fullHeight={false}
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/4.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'bottom',
                        },
                        style: {
                            width: '70%',
                            maxWidth: '75rem',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/4_bg.png`,
                imgStyle: {
                    objectFit: 'contain',
                    objectPosition: 'bottom',
                    width: '100%',
                },
            }}
            background={colors.bgLightGreen}
        />
        <TextContent
            headerTheme="dark"
            textColor={colors.white}
            content={{
                section: `case.${CASE_ID}.section5.name`,
                title: `case.${CASE_ID}.section5.title`,
                text: `case.${CASE_ID}.section5.text`,
            }}
            sectionTitleColor="rgba(0, 0, 0, 0.5)"
            background={colors.bgGreen}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            fullHeight={false}
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/5.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                        style: {
                            width: '90%',
                            maxWidth: '95rem',
                        },
                    },
                },
            ]}
            background={`linear-gradient(180deg, ${colors.bgGreen} 0%, #86D0A8 100%)`}
        />
        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section6.name`,
                title: `case.${CASE_ID}.section6.title`,
                text: `case.${CASE_ID}.section6.text`,
            }}
            background={`linear-gradient(180deg, #86D0A8 0%, #ECFFE3 100%)`}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            fullHeight={false}
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/6.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                        style: {
                            width: '100%',
                            maxWidth: '110rem',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/6_bg.png`,
                imgStyle: {
                    objectFit: 'cover',
                },
            }}
            background={colors.bgLightGreen}
        />
        {/* @ts-expect-error */}
        <Testimonial
            headerTheme="dark"
            background={colors.bgLightGreen}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/testimonial.png`,
                    },
                },
            }}
            type="PROFILE_RIGHT"
            textColor={colors.black}
        />
        {/* @ts-expect-error */}
        <Team headerTheme="light" content={team} background={colors.bgDarkGreen} textColor={colors.white} />
    </>
);

const noPadding = {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
};

export default Co2in;
